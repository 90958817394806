import React, { FC } from 'react';
import { Modal } from 'react-bootstrap';
import './styles.scss';

const CustomModal: FC<any> = props => {
  return (
    <Modal size="lg" {...props} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body>
        {props?.title ? (
          <p className="text-center">{props.title}</p>
        ) : (
          <>
            <p className="text-center">You have reached your search limit for the day.</p>
            <p className="text-center">
              InspectContent is powered by
              <a href="https://hexomatic.com" className="purple-link">
                {'  '}Hexomatic
              </a>
              ,
              <br />
              the no-code work automation platform. &#128640;
            </p>

            <p className="text-center">
              <a href="https://hexomatic.com/#registration" className="sign-up">
                Sign up {'  '}
              </a>
              <span className='bold'>for Hexomatic account</span> to unlock more contact searches via the InspectContent automation and 60+ ready made growth hacking automations.{' '}
            </p>
          </>
        )}
        <div className="d-flex justify-content-end">
          <button className="button" onClick={() => window.location.href = 'https://hexomatic.com/#registration'}>
            Unlock more searches
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CustomModal;
